import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "src/app/shared/core/auth-guard.service";
import { ComponentRoutingModule } from "./component-routing.module";
import { ComponentComponent } from "./component.component";
import { ButtonModule } from "primeng/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastModule } from "primeng/toast";
import { DropdownModule } from "primeng/dropdown";
import { PaginatorModule } from "primeng/paginator";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { DisabledPageComponent } from './disabled-page/disabled-page.component';

@NgModule({
  declarations: [ComponentComponent, DisabledPageComponent],
  imports: [
    CommonModule,
    ComponentRoutingModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DropdownModule,
    PaginatorModule,
    MessageModule,
    MessagesModule],
  providers: [AuthGuard],
})
export class ComponentModule {}
